import React from "react"
import getYouTubeId from "get-youtube-id"
import YouTube from "react-youtube"
import BasePortableText from "@sanity/block-content-to-react"
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
// import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism"
import Highlight, { defaultProps } from "prism-react-renderer"
import github from "prism-react-renderer/themes/github"
import { imageUrlFor } from "./lib/image-url"

const BlockRenderer = props => {
  const { style = "normal" } = props.node
  if (style === "normal") return <div>{props.children}</div>
  return BasePortableText.defaultSerializers.types.block(props)
}

const serializers = {
  marks: {
    inlineicon: props => {
      if (props.mark.asset) {
        return (
          <>
            <img
              className="inline-icon"
              src={
                imageUrlFor(props.mark)
                  .width(30)
                  .url() || ""
              }
              alt={""}
            />
            {props.children}
          </>
        )
      } else {
        return null
      }
    },
    x2: props =>
      null || <span style={{ fontSize: "1.3em" }}>{props.children}</span>,
    strong: props => null || <strong>{props.children}</strong>,
    em: props => null || <em>{props.children}</em>,
    textLeft: ({ children }) => {
      return <div style={{ textAlign: "left" }}>{children}</div>
    },
    textCenter: ({ children }) => {
      return <div style={{ textAlign: "center" }}>{children}</div>
    },
    textRight: ({ children }) => {
      return <div style={{ textAlign: "right" }}>{children}</div>
    },
    textJustify: ({ children }) => {
      return <div style={{ textAlign: "justify" }}>{children}</div>
    },
  },
  types: {
    block: BlockRenderer,
    youtube: ({ node }) => {
      const { url } = node
      const id = getYouTubeId(url)
      return (
        <div className="text-center" style={{ margin: "2rem" }}>
          <YouTube videoId={id} />
        </div>
      )
    },
    code: ({ node }) => (
      <Highlight
        {...defaultProps}
        theme={github}
        code={node.code}
        language={node.language || "text"}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={{ ...style, overflowX: "auto" }}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    ),
  },
}

export default serializers
